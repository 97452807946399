
.loading-page {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 256px;
  height: 256px;
  /* background-color: rgb(131, 162, 32); */
  margin: 100px 35%;
  border-radius: 100%;
  display: inline-block;
      -webkit-animation: sk-bouncedelay 2s infinite ease-in-out both;
  animation: sk-bouncedelay 2s infinite ease-in-out both;
  /* background-repeat:no-repeat;
background-size:contain;
background-position:center; */
}

.bouncers {
    display: flex;
    justify-content: center;
  }
  .bounce1 {
    animation-delay: -0.80s;
  }
  .bounce2 {
    animation-delay: -0.60s;
  }
  .bounce3 {
    animation-delay: -0.40s;
  }
  .bounce4 {
    animation-delay: -0.20s;
  }

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
}
