@import url("https://fast.fonts.net/lt/1.css?apiType=css&c=9bf1ee30-752c-4d2b-8022-0991ce25554a&fontids=692088");
@font-face{
    font-family:"Century Gothic W01";
    src:url("Fonts/692088/bd45538f-4200-4946-b177-02de8337032d.eot?#iefix");
    src:url("Fonts/692088/bd45538f-4200-4946-b177-02de8337032d.eot?#iefix") format("eot"),url("Fonts/692088/700cfd4c-3384-4654-abe1-aa1a6e8058e4.woff2") format("woff2"),url("Fonts/692088/9908cdad-7524-4206-819e-4f345a666324.woff") format("woff"),url("Fonts/692088/b710c26a-f1ae-4fb8-a9fe-570fd829cbf1.ttf") format("truetype");
}

body {
    margin:0;
    padding:0;
    font-family: Century Gothic W01;
}

a {
    text-decoration: none;
    color: inherit;
}

.link_logo {
    display: inline-block;
}

.bck_blue {
    background: #000000;
    width: auto;
    ul {
      display: none;
      @media only screen and (min-width: "760px") {
        display: grid;
      }
    }
}

.bck_white {
    margin-top: 125px;
    background: #ffffff;
    width: auto;
}

.bck_lblue {
    background: #98c5e9;
}

.img_cover {
    background-size: cover !important;
}
.container {
    width:1024px;
    margin: 0 auto;
}

input {
    background: #fafafa;
    border: 1px solid #e9e9e9;
    font-size: 17px;
    padding: 8px;
    border-radius: 3px;
    font-family: Roboto;
    font-weight: 300;
    width: 100%;
    box-sizing: border-box;
}

select {
    font-size: 16px;
    height: 38px;
    border: 1px solid #e9e9e9;
    border-radius: 3px;
    width: 100%;
}


button {
    background: #0d1831;
    color: #ffffff;
    border: 1px solid #0d1831;
    padding: 5px 10px;
    margin-top: 10px;
    font-size: 15px;
    border-radius: 3px;
}

.error_label {
    color: #F44336;
    font-weight: 600;
    margin: 10px 0px;
}

.success_label {
    color: #98c5e9;
    font-weight: 600;
    margin: 10px 0px;
}

.label_inputs {
    font-size: 20px;
    margin: 10px 0px;
    font-weight: 300;
    background: #e5f4ff;
    padding: 10px;
    display: inline-block;
}

.split_fields {
    margin: 30px 0px;
    padding: 20px 0px;
    border-top: 2px solid #dddddd;
}

.split_fields.last {
    margin-bottom: 0;
    padding-bottom: 0;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #cecece;
    text-align: center;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: #cecece;
    text-align: center;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: #cecece;
    text-align: center;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: #cecece;
    text-align: center;
  }

  .each-fade {
      color: white;
  }

.image_upload_container {
    position: relative;
}

.image_upload_container .remove {
    top: 0;
    position: absolute;
    right: 0;
    background: #98c6e9;
    color: #ffffff;
    border: 1px solid white;
    padding: 10px;
}

.not_found_container {
    text-align: center;
    margin-top: 96px;
    padding: 20px;
    background-size: 5px 5px !important;
    min-height: 100vh;
    text-align: center;
    font-size: 90px;
    font-family: 'Righteous', cursive;
    color: #d5ebfe;
}


/*=============================
            HEADER FOOTER
===============================*/

footer {
    text-align: center;
    font-size: 16px;
    color: #636363;
    padding: 30px 0px;
}

footer .img_cover{
   margin:0 auto;
}

footer .footer_discl {
    margin: 10px 0px;
}


/*=============================
            FEATURED
===============================*/

.featured_wrapper {
    margin: 0 auto;
    width: auto;
    margin-top: 120px;
    /* height: 800px; */
    overflow: hidden;
}

.home_column {
    color: #ffffff;
}

.featured_stripes {
    position: relative
}

/* .btn-secondary {
    background-color: #2b2b2b;
    padding: 10px;
    border-radius: 5px;
} */

.featured_stripes .stripe {
    width: 230px;
    height: 1200px;
    position: absolute;
}

.featured_number {
    font-size: 270px;
    color: #ffffff;
    transform: translate(260px,170px);
    position: absolute;
}

.featured_image {
    width: 100%;
    height: 600px;
    background-size: contain !important;
    background-repeat: no-repeat !important;

}
.featured_first {
    position: 50% 50%;
    background: #0e1731;
    color: #ffffff;
    font-size: 92px;
    text-transform: uppercase;
    padding: 0px 20px;
}

.featured_second {
    margin-top: 70px;
    color: #aeaeae;
    font-style: italic;
    font-size: 24px;
    text-transform: uppercase;
    padding: 0px 20px;
}

.home_card {
    padding: 5px;
    margin-top: 70px;
    border-radius: 2px;
    /* background-color: #000000; */
    background-color: #262626eb;
}

/*=============================
            MATCHES HOME
===============================*/

.lastes_wrapper {
    background: #000000;
    margin-top: 70px;
    padding: 50px 0px;
}

.home_matches_wrapper .home_matches {
    flex-wrap: wrap;
    display: flex;
    margin-top: 60px;
}

.home_matches_wrapper .home_matches .item {
    width: 50%;
    margin: 35px 0px;
}

.home_matches_wrapper .home_matches .item .wrapper {
    padding-right:30px;
}


/*=============================
            MATCHES BLOCK
===============================*/

.match_block .match_date{
    color:#ffffff;
    font-size:18px;
}

.match_block .match_wrapper .match_top,
.match_block .match_wrapper .match_bottom {
    display: flex;
}
.match_block .match_wrapper .match_bottom {
    margin-top: 5px;
}

.match_block .match_wrapper .match_top .left,
.match_block .match_wrapper .match_bottom .left {
    background: #ffffff;
    font-family: 'Righteous', cursive;
    flex-grow: 1;
    margin-right: 5px;
    padding: 10px;
}

.match_block .match_wrapper .match_top .left div,
.match_block .match_wrapper .match_bottom .left div {
    float:left
}

.match_block .match_wrapper .match_top .left .icon,
.match_block .match_wrapper .match_bottom .left .icon {
    width: 50px;
    height: 50px;
    background-size: cover !important;
}
.match_block .match_wrapper .match_top .left .team_name,
.match_block .match_wrapper .match_bottom .left .team_name {
    font-size: 30px;
    padding: 6px 10px;
}


.match_block .match_wrapper .match_top .right,
.match_block .match_wrapper .match_bottom .right {
    background: #0e1731;
    width: 70px;
    color: #ffffff;
    font-size: 50px;
    text-align: center;
    padding-top: 6px;
}

.match_box_big {
    width:100%;
    border-bottom: 2px solid #98c6e9;
    padding: 20px;
    box-sizing: border-box;
    height: 143px;
    clear: both;
}

.match_box_big .block_wraper {
    display: inline-block;
    margin-right: 50px;
    float: left;
}

.match_box_big .block {
    display: flex;
}

.match_box_big .icon{
    width: 50px;
    height: 50px;
    background-size: cover !important;
}

.match_box_big .block .team,
.match_box_big .block .result {
    font-size: 21px;
    line-height: 51px;
    margin-left: 18px;
    font-weight: 300;
}

.match_box_big .block .result {
    font-weight: 500;
}

.block_wraper.nfo {
    border-left: 1px solid #98c6e9;
    height: 102px;
    padding-left: 10px;
    font-weight: 300;
    color: #5e5e5e;
}

.block_wraper.nfo div {
    margin: 5px 0px;
}

.match_filters {
     display: flex;
     border-bottom: 1px solid #01285e;
     background: #dff1ff;
}
.match_filters .tag {
    font-weight: 300;
    margin-bottom: 5px;
    color: #656565;
}
.match_filters .option {
    background: #ffffff;
    padding: 5px 9px;
    border-radius: 3px;
    font-size: 13px;
    margin-right: 5px;
    cursor: pointer;
}

.match_filters .option.active {
    background: #62a2d3;
    color: #fff;
}

.match_filters_box {
    padding: 10px 20px;
    border-right: 1px solid #01285e;
    background: #dff1ff;
}

.match_filters .cont {
    display: flex;
}


/*=============================
            MATCHES BLOCK
===============================*/


.home_meetplayers {
    background: #ffffff;
}

.home_meetplayers_wrapper {
    padding-bottom: 100px;
    min-height: 600px;
}

.home_meetplayers .home_card_wrapper {
    position: relative;
    float: left;
    width: 630px;
    min-height: 600px;
}

.home_meetplayers .home_text_wrapper {
    float: right;
    width: 390px;
}

/*=============================
            MATCHES BLOCK
===============================*/


.player_card_wrapper {
    background: #ffffff;
    border: 1px solid #ececec;
    width: 300px;
    padding: 10px 10px 20px 10px;
    font-family: 'Righteous';
}
.player_card_wrapper .player_card_thmb {
    border: 1px solid #eeeeee;
    width:100%;
    height:250px;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: contain !important;
}

.player_card_wrapper .player_card_nfo {
    position: relative;
}
.player_card_wrapper .player_card_number {
    color: #d6ecfe;
    font-size: 185px;
    text-align: right;
    line-height: 160px;
}

.player_card_wrapper .player_card_name {
    position: absolute;
    bottom: 0px;
}

.player_card_wrapper .player_card_name span {
    display: block;
    font-size: 25px;
    color: #0d1831;
}

.home_text_wrapper {
    margin-top: 70px;
}


/*=============================
         PROMOTION
===============================*/

.promotion_wrapper {
    padding: 30px 0px 130px 0px;
}

.promotion_wrapper .promotion_animation {
    display: flex;
    padding: 80px 0px 30px 0px;
}

.promotion_wrapper .promotion_animation .left {
    text-transform: uppercase;
    text-transform: uppercase;
    font-size: 150px;
    font-family: 'Righteous', cursive;
    flex-grow: 1;
    color:#d5ebfd;
    line-height: 135px;
}
.promotion_wrapper .promotion_animation .left span {
    display:block;
 }

.promotion_wrapper .promotion_animation .right {
    line-height: 135px;
}

.promotion_wrapper .promotion_animation .right div{
    width: 280px;
    height: 280px;
    background-size: contain !important;
}

.promotion_wrapper .enroll_wrapper {
    text-align: center;
}

.promotion_wrapper .enroll_title {
    font-family: 'Righteous', cursive;
    font-size: 26px;
    color: #4e4e4e;
}

.promotion_wrapper .enroll_discl {
    font-weight: 300;
    font-size: 12px;
    width: 271px;
    margin: 0 auto;
    padding: 10px 0px;
}

.promotion_wrapper .enroll_input {
    width: 330px;
    margin: 0 auto;
    padding: 10px 0px;
}

/*=============================
         THE MATCHES
===============================*/

.the_matches_container {
    margin-top: 96px;
}

.the_matches_wrapper {
    display: flex;
}

.the_matches_wrapper .left {
    flex-grow: 1;
}

.the_matches_wrapper .right {
   width:400px;
}

.league_table_wrapper {
    border-left: 1px solid #98c6e9;
}
.league_table_wrapper .title {
    padding: 22px;
    font-size: 22px;
    color: #ffffff;
    background: #98c6e9;
    border-bottom: 1px solid #01285e;
}

.the_matches_wrapper .progress {
    text-align: center;
    padding: 60px 0px;
}




/*=============================
         ADMIN
===============================*/

.admin_container {
    margin-top: 96px;
    display:flex;
}

.admin_container .admin_left_nav {
    min-height: 100vh;
    background: #282828;
    border-right: 2px solid #01285e;
}

.admin_container  .admin_right {
    flex-grow: 1;
}
.admin_container  .admin_progress {
    text-align: center;
    margin: 20px 0px;
}

.admin_container .matches_tag_red {
    font-weight: 700;
    color: #F44336;
}

.admin_container .matches_tag_green {
    font-weight: 700;
    color: #4CAF50;
}

.admin_container h2 {
    margin: 0px 0px 50px 0px;
    border-bottom: 1px solid #dbdbdb;
    color: #414141;
    font-size: 40px;
}

.editmatch_dialog_wrapper,
.editplayers_dialog_wrapper {
    padding: 20px;
    width: 480px;

}

.slider_title {
    color: white;
}

.select_team_layout .wrapper{
    display: flex;
}

.select_team_layout .wrapper .left {
    flex-grow: 1;
}

.select_team_layout input[name=result_local_input],
.select_team_layout input[name=result_away_input] {
    width: 60px;
    text-align: center;
    background: #98c6e9;
    color: #ffffff;
}

.admin_submit button {
    font-size: 20px;
    margin: 40px 0px;
    background: #009688;
}

/*=============================
         THE TEAM
===============================*/

.the_team_container {
    margin-top: 96px;
    padding: 20px;
    background-size: 5px 5px !important;
    min-height:100vh;
}

.team_category_wrapper .title {
    font-size: 90px;
    margin: 30px 0px;
    font-family: 'Righteous', cursive;
    color: #d5ebfe;
}

.team_category_wrapper .team_cards {
    display: flex;
    flex-wrap: wrap;
}
.team_category_wrapper .team_cards .item{
    margin-right: 20px;
    margin-bottom: 20px;
}

/*=============================
            SIGN IN
===============================*/

.signin_wrapper {
    margin-top: 96px;
    padding: 20px;
    min-height: 70VH;
    text-align: center;
}

.signin_wrapper form {
    width: 350px;
    margin: 0 auto;
}

.signin_wrapper form button {
    width: 100%;
    padding: 10px;
}

.signin_wrapper h2 {
    font-family: 'Righteous', cursive;
    color: #98c6e9;
    font-size: 50px;
}

.user_dashboard {
    font-size: 60px;
    font-family: 'Righteous', cursive;
    color: #d5ebfe;
    text-align: center;
    margin-top: 200px;
}
