.partners_wrapper {
    min-height: 810px;
    width: 100%;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    -webkit-animation: img-expand ease-in-out 12s infinite alternate;
    -moz-animation: img-expand ease-in-out 12s infinite alternate;
    -o-animation: img-expand ease-in-out 12s infinite alternate;
    animation: img-expand ease-in-out 12s infinite alternate;
    @keyframes img-expand {
      0%{
        transform: scale(1, 1);
      }
      100%{
        transform: scale(1, 1.05);
      }
    }
    .logos-wrapper {
      @media only screen and (min-width: "760px") {
        padding: 0 12%;
      }
      padding: 25px 0;
      color: white;
      h2 {
        margin: 5% 0;
        font-size: 24px;
        font-family: Century Gothic W01;
      }
      .client-logo {
        background-size: cover;
        margin: 30px;
        background-position: center;
      }
      .button-anime:before{
        content: '';
        position: absolute;
        top: -2px;
        left: -2px;
        width: 0;
        height: 0;
        background: transparent;
        border: 4px solid transparent;
        color: white;
      }
      .button-anime:hover:before{
        animation: animate 1s linear forwards;
      }
      @keyframes animate{
        0%{
          width: 0;
          height: 0;
          border-top-color: #799900;
          border-bottom-color: transparent;
          border-left-color: transparent;
          border-right-color: transparent;
        }
        50%{
          width: 100%;
          height: 0;
          border-top-color: #799900;
          border-bottom-color: transparent;
          border-left-color: transparent;
          border-right-color: #799900;
        }
        100%{
          width: 100%;
          height: 100%;
          border-top-color: #799900;
          border-bottom-color: transparent;
          border-left-color: transparent;
          border-right-color: #799900;
        }
      }
      .button-anime::after{
        content: '';
        position: absolute;
        top: -2px;
        left: -2px;
        width: 0;
        height: 0;
        background: transparent;
        border: 4px solid transparent;
      }
      .button-anime:hover:after{
        animation: animate2 1s linear forwards;
        animation-delay: 1s;
      }
      @keyframes animate2{
        0%{
          width: 0;
          height: 0;
          border-top-color: transparent;
          border-bottom-color: transparent;
          border-left-color: #799900;
          border-right-color: transparent;
        }
        50%{
          width: 0;
          height: 100%;
          border-top-color: transparent;
          border-bottom-color: #799900;
          border-left-color: #799900;
          border-right-color: transparent;
        }
        100%{
          width: 100%;
          height: 100%;
          border-top-color: transparent;
          border-bottom-color: #799900;
          border-left-color: #799900;
          border-right-color: transparent;
        }
      }
    }
}
