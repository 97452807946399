@mixin fullHeight {
  // display: flex;
  // flex-direction: column;
  // flex: 1;
}

.navbar-header {
  @include fullHeight;
  button {
    :hover {
        background-color: dimgray;
        color: #799900;
        border-radius: 3px;
        padding: 5px;
    }
  }
  .navbar-button {
    font-family: Century Gothic W01;
  }
  .content-layout {
    display: flex;
    .sidebar {
      @extend .sidebar-base;
    }
    .content {
      @include fullHeight;
      min-height: calc(100vh - 64px);
    }
  }
}

.footer-wrapper{
    height: 560px;
    color: white;
    font-size: 20px;
    .webste-by {
      margin-top: 25px;
      font-size: 15px;
      color: #799900;
      span {
        color: white;
      }
    }
    .info-wrapper {
      margin-top: 50px;
      span {
        color: #799900;
      }
      .text-wrapper {
        margin-top: 40px;
        margin-left: 10px;
        margin-right: 10px;
        font-weight: 800;
        font-size: 15px;
        @media only screen and (min-width: "760px") {
          margin-top: 40px;
          font-weight: 800;
          font-size: 25px;
        }
      }
      .icons-wrapper {
        .wrapper {
          display: -webkit-box;
        }
        width: 100%;
        margin-top: 60px;
        .icons {
          margin: 0 15px;
          width: 48px;
          height: 48px;
          display: -webkit-inline-box;
        }
      }
    }
    .footer_img {
      margin-top: 25px;
    }
    .footer_text {
        color: white;
        font-size: 20px;
    }
}

.sidebar-drawer {
  .sidebar {
    @extend .sidebar-base;
    height: 100%;
    color: #799900
  }
}

.sidebar-base {
  padding-top: 50px;
  background-color: #231F20;
  min-width: 170px;
  .menu {
    display: grid;
    .link {
      margin: 10px;
      padding: 10px;
      color: rgb(124, 124, 124);
      border-radius: 3px;
      &:hover {
        background-color: dimgray;
        color: #799900;
        border-radius: 3px;
        padding: 5px;
      }
    }
  }
}
