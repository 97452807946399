.video_wrapper {
    margin-top: 120px;
    video {
      width: 100%;
      height: auto;
    }
}

.video-container {
  @media only screen and (min-width: "550px") {
    margin-top: '100px';
  }
  @media only screen and (min-width: "940px") {
    margin-top: '85px';
  }
  @media only screen and (min-width: "1200px") {
    margin-top: '85px';
  }
}

.first-video {
  width: 100%;
  min-width: 100%;
  min-height: 100%;
}
