.capri_wrapper {
    height: 650px;
    width: 100%;

    .mvg_logo {
      padding-top: 200px;
      color: white;
      width: 100%;
    }
}
