.obras_wrapper {
  min-height: 810px;
  width: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  -webkit-animation: img-expand ease-in-out 12s infinite alternate;
  -moz-animation: img-expand ease-in-out 12s infinite alternate;
  -o-animation: img-expand ease-in-out 12s infinite alternate;
  animation: img-expand ease-in-out 12s infinite alternate;
  @keyframes img-expand {
    0%{
      transform: scale(1, 1);
    }
    100%{
      transform: scale(1, 1.05);
    }
  }
  .mvg-text {
    padding: 15% 30%;
    font-size: 16px;
    color: white;
    letter-spacing: 2px;
    line-height: 22pt;
    .house-icon {
      background-color: #ffffff52;
      width: 60px;
      border: 2px solid #799900;
      border-radius: 50%;
      height: 60px;
      .img-icon {
        margin: 9px;
      }
      &:hover {
        box-shadow: 0 5px 10px lighten(#212121 , 10%);
        cursor: pointer;
        opacity: 1;
        background-color: #fff;
      }
    }
    .obras-title {
      margin-top: 60px;
    }
    .icons {
      margin: 20px;
      padding: 10px;
      opacity: 0.5;
      color: #fff;
      background-color: #000;
      border: 2px solid #799900;
      border-radius: 50%;
      &:hover {
          box-shadow: 0 5px 10px lighten(#212121 , 10%);
          cursor: pointer;
          opacity: 1;
      }
    }
    .button-anime:before{
      content: '';
      position: absolute;
      top: -2px;
      left: -2px;
      width: 0;
      height: 0;
      background: transparent;
      border: 4px solid transparent;
      color: white;
    }
    .button-anime:hover:before{
      animation: animate 1s linear forwards;
    }
    @keyframes animate{
      0%{
        width: 0;
        height: 0;
        border-top-color: #799900;
        border-bottom-color: transparent;
        border-left-color: transparent;
        border-right-color: transparent;
      }
      50%{
        width: 100%;
        height: 0;
        border-top-color: #799900;
        border-bottom-color: transparent;
        border-left-color: transparent;
        border-right-color: #799900;
      }
      100%{
        width: 100%;
        height: 100%;
        border-top-color: #799900;
        border-bottom-color: transparent;
        border-left-color: transparent;
        border-right-color: #799900;
      }
    }
    .button-anime::after{
      content: '';
      position: absolute;
      top: -2px;
      left: -2px;
      width: 0;
      height: 0;
      background: transparent;
      border: 4px solid transparent;
    }
    .button-anime:hover:after{
      animation: animate2 1s linear forwards;
      animation-delay: 1s;
    }
    @keyframes animate2{
      0%{
        width: 0;
        height: 0;
        border-top-color: transparent;
        border-bottom-color: transparent;
        border-left-color: #799900;
        border-right-color: transparent;
      }
      50%{
        width: 0;
        height: 100%;
        border-top-color: transparent;
        border-bottom-color: #799900;
        border-left-color: #799900;
        border-right-color: transparent;
      }
      100%{
        width: 100%;
        height: 100%;
        border-top-color: transparent;
        border-bottom-color: #799900;
        border-left-color: #799900;
        border-right-color: transparent;
      }
    }
  }
}
