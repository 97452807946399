.about_wrapper {
    min-height: 550px;
    width: 100%;
    color: #231F20;
    h1 {
      text-align: left;
      letter-spacing: 2px;
    }

    p {
      text-align: left;
      letter-spacing: 2px;
      line-height: 22pt;
    }
    .text-wrapper {
      margin-top: 160px;
    }
    .black-wrapper {
      animation-name: backswitch;
      animation-duration: 3s;
      animation-fill-mode: forwards;
      min-width: 100%;
      position: absolute;
      @media only screen and (min-width: "100px") {
        min-height: 786px;
      }
      @media only screen and (min-width: "591px") {
        min-height: 550px;
      }
    }
}

@keyframes backswitch {
  0% {background: rgba(242, 242, 242, 0);}
  20% {background: rgba(242, 242, 242, 0.2);}
  40% {background: rgba(242, 242, 242, 0.4);}
  60% {background: rgba(242, 242, 242, 0.6);}
  80% {background: rgba(242, 242, 242, 0.8);}
  100% {background: rgba(242, 242, 242, 0.8);}
}
