.mision_wrapper {
    min-height: 650px;
    width: 100%;
    background-color: #231F20;
    color: white;
    h1 {
      text-align: right;
      letter-spacing: 2px;
    }
    p {
      text-align: right;
      letter-spacing: 2px;
      line-height: 20pt;
    }
    .img-wrapper {
      border: 2px solid #799900;
      @media only screen and (min-width: "100px") {
        margin-top: 50px;
        max-width: 250px;
      }
      @media only screen and (min-width: "770px") {
        margin-top: 0;
        max-width: 300px;
      }
      height: 260px;
      &.bottom {
        @media only screen and (min-width: "100px") {
          margin-bottom: 50px;
        }
        @media only screen and (min-width: "950px") {
          margin-bottom: 0;
        }
      }
    }
    .text-wrapper {
      min-height: 200px;
      border: 2px solid #fff;
      padding: 30px;
      margin: 50px 30px;
    }
}
